<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-button variant="success" @click="openModal">ثبت دسته‌بندی</b-button>
        <router-link to="/admin/categories/sort" class="btn btn-warning mr-3" >مرتب سازی</router-link>
        <b-card title="دسته‌بندی‌ها" class="mt-3">

            <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="title">اسم</label>
                                    <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <b-form-group label="وضعیت" label-for="status">
                                    <b-form-select id="status" v-model="searchParams.status">
                                        <b-form-select-option value="1">فعال</b-form-select-option>
                                        <b-form-select-option value="0">غیرفعال</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group label="ویژه" label-for="special">
                                    <b-form-select id="special" v-model="searchParams.special">
                                        <b-form-select-option value="1">ویژه</b-form-select-option>
                                        <b-form-select-option value="0">غیر ویژه</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data">

                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(children)="data">
                    <p v-if="data.item.children">{{ data.item.children.length }}</p>
                </template>

                <template v-slot:cell(special)="data">
                    <i v-if="data.item.special == '1'" class="fas fa-star" style="color: gold;"></i>
                    <i v-else-if="data.item.special == '0'" class="fas fa-star"></i>
                </template>

                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
                    <span class="badge badge-light" v-else>غیرفعال</span>
                </template>

                <template v-slot:cell(edit)="data">
                    <button class="btn btn-primary" @click="editItem(data.index, data.item)"><i
                            class="fa fa-edit"></i></button>
                </template>

                <template v-slot:cell(delete)="data">
                    <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i
                            class="fa fa-close"></i></button>
                    <!-- <button class="btn btn-danger" @click="deleteItem(data.index, items.data)"><i
                            class="fa fa-close"></i></button> -->
                </template>
            </b-table>
        </b-card>

        <!-- <pagination :limit="2" v-if="items.data" :data="items" @pagination-change-page="loadItems"></pagination> -->

        <b-modal id="new-modal" title="ثبت دسته‌بندی" size="xl" hide-footer>
            <div v-if="items.data">
                <form @submit.prevent="newItem" id="new-item">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group>
                                <label for="name"> اسم <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-input id="name" name="name" :disabled="disabled"></b-form-input>
                            </b-form-group>

                            <b-form-group label="تصویر" label-for="image">
                                <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                            </b-form-group>
                            <b-row>
                                <b-col>
                                    <div class="form-group">
                                        <label class="custom-switch">
                                            <input type="checkbox" name="status" class="custom-switch-input"
                                                v-model="selected_status" :disabled="disabled">
                                            <span class="custom-switch-indicator"></span>
                                            <span class="custom-switch-description">وضعیت</span>
                                        </label>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div class="form-group">
                                        <label class="custom-switch">
                                            <input type="checkbox" name="special" class="custom-switch-input"
                                                :disabled="disabled">
                                            <span class="custom-switch-indicator"></span>
                                            <span class="custom-switch-description">ویژه</span>
                                        </label>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="form-group">
                                <label class="custom-switch">
                                    <input type="checkbox" v-model="selected_show_in_products" class="custom-switch-input"
                                        :disabled="disabled">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="custom-switch-description">نمایش در لیست محصولات</span>
                                </label>
                                <input type="hidden" name="show_in_products" :value="selected_show_in_products == 'on' || selected_show_in_products == 1 ? 1 : 0">
                            </div>
                            <div class="form-group">
                              <label class="custom-switch">
                                <input type="checkbox" v-model="selected_show_banner" class="custom-switch-input"
                                       :disabled="disabled">
                                <span class="custom-switch-indicator"></span>
                                <span class="custom-switch-description">نمایش در لیست محصولات</span>
                              </label>
                              <input type="hidden" name="show_in_products" :value="selected_show_banner == 'on' || selected_show_banner == 1 ? 1 : 0">
                              <input type="hidden" name="show_banner" :value="selected_show_banner == 'on' || selected_show_banner == 1 ? 1 : 0">
                            </div>
                            <b-form-group label="تصویر بنر" label-for="banner">
                                <b-form-file id="banner" name="banner" :disabled="disabled"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <categories :items="items.data" title="والد" />
                        </div>
                        <div class="col-md-4">
                            <!-- <b-form-group label="واحد" label-for="units">
                  <b-form-select id="units" name="unit" >
                    <b-form-select-option value="number">تعداد</b-form-select-option>
                    <b-form-select-option value="meter">متر</b-form-select-option>
                    <b-form-select-option value="kg"> کیلوگرم</b-form-select-option>
                  </b-form-select>
                </b-form-group> -->

                            <b-form-group>
                                <label for="varieties"> نوع تنوع <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-select id="varieties" name="variety" :disabled="disabled">
                                    <b-form-select-option value="color-size">رنگ و سایز</b-form-select-option>
                                    <!-- <b-form-select-option value="size"> سایز</b-form-select-option> -->
                                    <b-form-select-option value="color"> رنگ</b-form-select-option>
                                    <b-form-select-option value="title"> عنوان</b-form-select-option>
                                    <!-- <b-form-select-option value="weight"> وزن</b-form-select-option> -->
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="برندها" label-for="brands">
                                <multiselect :close-on-select="false" :clear-on-select="false" deselectLabel=""
                                    selectLabel="" selectedLabel="" v-model="newItems.selected_brands" :options="brands"
                                    :multiple="true" label="name" track-by="id" placeholder="" :disabled="disabled">
                                </multiselect>
                                <div v-for="item in newItems.selected_brands" :key="item.id">
                                    <input type="hidden" name="brands[]" :value="item.id">
                                </div>
                            </b-form-group>
                            <b-form-group label="ویژگی‌ها" label-for="attributes">
                                <multiselect :close-on-select="false" :clear-on-select="false" deselectLabel=""
                                    selectLabel="" selectedLabel="" v-model="newItems.selected_attributes"
                                    :options="attributes" :multiple="true" label="name" track-by="id" placeholder=""
                                    :disabled="disabled">
                                </multiselect>
                                <div v-for="item in newItems.selected_attributes" :key="item.id">
                                    <input type="hidden" name="attributes[]" :value="item.id">

                                </div>
                            </b-form-group>
                        </div>
                    </div>
                    <!-- <input type="hidden" name="variety" value="title"> -->
                    <input type="hidden" name="unit" value="number">

                    <div class="clearfix">
                        <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal id="edit-modal" title="ویرایش دسته‌بندی" size="xl" hide-footer>
            <div v-if="edit.id">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group>
                                <label for="name"> اسم <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-input id="name" name="name" v-model="edit.name" :disabled="disabled">
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="تصویر جدید" label-for="image">
                                <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                            </b-form-group>
                            <b-row>
                                <b-col>
                                    <div class="form-group">
                                        <label class="custom-switch">
                                            <input type="checkbox" name="status" class="custom-switch-input"
                                                v-model="edit.status" :disabled="disabled">
                                            <span class="custom-switch-indicator"></span>
                                            <span class="custom-switch-description">وضعیت</span>
                                        </label>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div class="form-group">
                                        <label class="custom-switch">
                                            <input type="checkbox" name="special" class="custom-switch-input"
                                                v-model="edit.special" :disabled="disabled">
                                            <span class="custom-switch-indicator"></span>
                                            <span class="custom-switch-description">ویژه</span>
                                        </label>
                                    </div>
                                </b-col>
                            </b-row>
                            <div v-if="edit.parent_id != null" class="form-group">
                                <label class="custom-switch">
                                    <input type="checkbox" class="custom-switch-input"
                                        v-model="edit.show_in_products" :disabled="disabled">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="custom-switch-description">نمایش در لیست محصولات</span>
                                </label>
                                <input type="hidden" name="show_in_products" :value="edit.show_in_products == 'on' || edit.show_in_products == 1 ? 1 : 0">
                            </div>
                            <input v-else-if="edit.parent_id == null" type="hidden" name="show_in_products" value="1">
                            <div class="form-group">
                              <label class="custom-switch">
                                <input type="checkbox" class="custom-switch-input"
                                       v-model="edit.show_banner" :disabled="disabled">
                                <span class="custom-switch-indicator"></span>
                                <span class="custom-switch-description">نمایش بنر</span>
                              </label>
                              <input type="hidden" name="show_banner" :value="edit.show_banner == 'on' || edit.show_banner == 1 ? 1 : 0">
                            </div>
                          <b-form-group label="تصویر بنر" label-for="banner">
                                <b-form-file id="banner" name="banner" :disabled="disabled"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <categories :items="items.data" :exclude="edit.id" title="والد جدید" />
                            <category-tree :items="edit.children" :edit="editItem" :url="url"></category-tree>
                        </div>

                        <div class="col-md-4">
                            <!-- <b-form-group label="واحد" label-for="units">
                                <b-form-select id="units" name="unit" v-model="edit.unit">
                                    <b-form-select-option value="number">تعداد</b-form-select-option>
                                    <b-form-select-option value="meter">متر</b-form-select-option>
                                    <b-form-select-option value="kg"> کیلوگرم</b-form-select-option>
                                </b-form-select>
                            </b-form-group> -->

                            <b-form-group>
                                <label for="varieties"> نوع تنوع <i class="fas fa-asterisk text-danger"
                                        style="font-size: 0.5rem;"></i></label>
                                <b-form-select id="varieties" name="variety" v-model="edit.variety"
                                    :disabled="disabled">
                                    <b-form-select-option value="color-size">رنگ و سایز</b-form-select-option>
                                    <!-- <b-form-select-option value="size"> سایز</b-form-select-option> -->
                                    <b-form-select-option value="color"> رنگ</b-form-select-option>
                                    <b-form-select-option value="title"> عنوان</b-form-select-option>
                                    <!-- <b-form-select-option value="weight"> وزن</b-form-select-option> -->
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="برندها" label-for="brands">
                                <multiselect :close-on-select="false" :clear-on-select="false" deselectLabel=""
                                    selectLabel="" selectedLabel="" v-model="edit.brands" :options="brands"
                                    :multiple="true" label="name" track-by="id" placeholder="" :disabled="disabled">
                                </multiselect>
                                <div v-for="item in edit.brands" :key="item.id">
                                    <input type="hidden" name="brands[]" :value="item.id">
                                </div>
                            </b-form-group>
                            <b-form-group label="ویژگی‌ها" label-for="attributes">
                                <multiselect deselectLabel="" selectLabel="" selectedLabel="" v-model="edit.attributes"
                                    :options="attributes" :multiple="true" label="name" track-by="id" placeholder=""
                                    :disabled="disabled">
                                </multiselect>
                                <div v-for="item in edit.attributes" :key="item.id">
                                    <input type="hidden" name="attributes[]" :value="item.id">
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                    <input type="hidden" name="unit" value="number">
                    <div class="clearfix">
                        <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
                    </div>
                    <input type="hidden" value="PUT" name="_method">
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import mixins from '../mixins/mixins'
    import categories from '../category/Categories'
    import CategoryTree from '../category/CategoryTree'

    export default {
        mixins: [mixins],
        components: {
            categories,
            CategoryTree,
        },
        data() {
            return {
                url: '/api/admin/categories',
                title: 'دسته بندی ها',
                dependencies: [],
                sizes: [],
                brands: [],
                colors: [],
                attributes: [],
                newItems: {
                    selected_brands: [],
                    selected_varieties: {},
                    selected_attributes: [],
                    selected_units: {}
                },
                table_fields: [{
                        key: 'id',
                        label: 'شناسه '
                    },
                    {
                        key: 'name',
                        label: 'اسم'
                    },
                    {
                        key: 'special',
                        label: 'ویژه'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'children',
                        label: 'زیر دسته'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ ثبت'
                    },
                    {
                        key: 'edit',
                        label: 'ویرایش '
                    },
                    {
                        key: 'delete',
                        label: 'حذف'
                    }
                ],
                selected_status: '1',
                selected_show_in_products: '1',
                selected_show_banner: '1',
                selected_filter_status: '',
                selected_filter_special: '',
            }
        },
        methods: {
            openModal() {
                this.newItems.selected_brands = []
                this.newItems.selected_varieties = {}
                this.newItems.selected_attributes = []
                this.newItems.selected_units = {}
                this.$root.$emit('bv::show::modal', 'new-modal')
            },
            editItem(index, item) {
                this.edit = {...item}
                this.$root.$emit('bv::show::modal', 'edit-modal')
                // this.$axios.get(this.$root.baseUrl + this.url + '/' + item.id + '/all')
                //     .then(response => {
                //         item = response.data.data
                //         item.index = index
                //         this.edit = item
                //         this.$root.$emit('bv::show::modal', 'edit-modal')
                //     })
            },
            newItem() {
                this.disabled = true
                let form = document.getElementById('new-item')
                let formData = new FormData(form)

                this.$axios.post(this.$root.baseUrl + this.url, formData)
                    .then(response => {
                        response.data.data.parent_id == null ? this.items.data.unshift(response.data.data) : this.$router.go(0);
                        // response.data.data.parent_id != null ? this.items.data.filter(item => item.id == +response
                        //     .data.data.parent_id)[0].children.unshift(response.data.data) : ''

                        this.$root.success_notification(response.data.message)
                        this.$root.$emit('bv::hide::modal', 'new-modal')
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            addToChildren(id, cat, categories) {

                let foundCat = categories.find(item => {
                    return item.id == id;
                })
                if (foundCat) {
                    foundCat.children.unshift(cat)
                    return;
                }
                categories.forEach(item => {
                    this.addToChildren(id, cat, item.children)
                })
            },
            itemEdited() {
                this.disabled = true
                let form = document.getElementById('edit-item')
                let formData = new FormData(form)

                this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
                    .then(response => {
                        this.$root.success_notification(response.data.message)
                        this.$router.go(0)
                        // response.data.data.parent_id != null && this.edit.parent_id != response.data.data ?  this
                        //     .items.data.filter(item => item.id == +response.data.data.parent_id)[0].children
                        //     .unshift(response.data.data)   : ''
                        this.$root.$emit('bv::hide::modal', 'edit-modal')
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            deleteItem(id) {
                window.swal({
                        title: "آیتم حذف شود؟",
                        text: "این عملیات غیر قابل بازگشت خواهد بود",
                        icon: "warning",

                        showCancelButton: true,
                        showConfirmButton: true,
                        dangerMode: true,
                        buttons: ["خیر", "بله حذف کن"]
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            this.$axios.delete(this.$root.baseUrl + this.url + `/${id}`)
                                .then((response) => {
                                    this.items.data = this.items.data.filter(item => item.id != id)
                                    window.swal({
                                        icon: "success",
                                        showConfirmButton: true,
                                        text: response.data.message,
                                        button: "باشه",
                                    })
                                })
                                .catch(error => {
                                    window.swal({
                                        title: 'خطا',
                                        showConfirmButton: true,
                                        text: error.response.data.message,
                                        button: "باشه",


                                    })
                                })
                        }
                    })
                    .catch(() => {

                    })
            },
        },
        created() {
            this.loadItems()
            this.searchParams = {
                name: '',
                status: '',
                special: ''
            }
            this.$axios.get(this.$root.baseUrl + this.url + '/create')
                .then(response => {
                    this.dependencies = response.data.data
                })
            this.$axios.get(this.$root.baseUrl + '/api/admin/list/sizes')
                .then(response => {
                    this.sizes = response.data.data
                })

            this.$axios.get(this.$root.baseUrl + '/api/admin/list/brands')
                .then(response => {
                    this.brands = response.data.data
                })

            this.$axios.get(this.$root.baseUrl + '/api/admin/list/colors')
                .then(response => {
                    this.colors = response.data.data
                })

            this.$axios.get(this.$root.baseUrl + '/api/admin/list/attributes')
                .then(response => {
                    this.attributes = response.data.data
                })
        }
    }
</script>
