<template>
  <div>
    <input type="hidden" v-if="category" name="category_id" :value="category" />
    <b-form-group>
      <label :for="name"
        >{{ title }}
        <i
          v-if="require"
          class="fas fa-asterisk text-danger"
          style="font-size: 0.5rem;"
        ></i>
      </label>
      <select
        :id="name"
        class="custom-select"
        @change="loadChildren($event)"
        :disabled="disabled"
      >
        <b-form-select-option selected>-</b-form-select-option>
        <b-form-select-option
          v-for="item in items"
          :key="item.id"
          :value="item.id"
        >
          {{
            !exclude || exclude != item.id ? item.name : ""
          }}</b-form-select-option
        >
      </select>
    </b-form-group>
    <div v-if="children.length > 0">
      <b-form-group
        :label-for="name"
        v-for="(chilrenItems, index) in children"
        :key="index"
      >
        <select
          :id="name"
          class="custom-select"
          @change="loadChildren($event, index)"
          :disabled="disabled"
        >
          <option selected>-</option>
          <option
            :value="item.id"
            v-for="item in chilrenItems"
            :key="item.id"
            >{{ item.name }}</option
          >
        </select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    items: Object | Array,
    name: String,
    title: String,
    exclude: Number,
    // eslint-disable-next-line vue/require-prop-type-constructor
    selected: Object | null,
    category: Number,
    from: String,
    require: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      url: "/api/admin/categories",
      children: [],
    };
  },
  methods: {
    loadChildren(event, index = null) {
      const value = event.target.value;
      const name = this.$props.name ? this.$props.name : "parent_id";
      window.$(event.target).attr("name", value ? name : "");
      this.$props.category = 0;

      if (index !== null) {
        this.children.splice(index + 1, this.children.length);
      } else {
        this.children = [];
      }

      if (value != "-") {
        this.$axios
          .get(this.$root.baseUrl + this.url + `/${value}`)
          .then((response) => {
            if (response.data.data.children.length > 0) {
              this.children.push(response.data.data.children);
            }
            if (this.$props.from == "product") {
              // value ? this.$parent.loadCategory(value) : ''
              this.$parent.$props.product.show_attributes = true;
              this.$parent.$props.product.brands = response.data.data.brands;
              this.$parent.$props.product.selected_category =
                response.data.data;
              this.$parent.$props.product.category = response.data.data;
              this.$parent.$props.product.attributes =
                response.data.data.attributes;
              this.$parent.setAttributes();
            }
          });
      }
    },
  },
  mounted() {},
};
</script>
